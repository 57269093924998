/*
 * Essential Imports
 */

import 'babel-polyfill';
import 'picturefill';
import 'lazysizes';
import './vendor/ls.object-fit';


/*
 * Essentail Fixes
 */

// Preload and No-JS
setTimeout(function () {
    document.documentElement.classList.remove('preload');
    document.documentElement.classList.remove('no-js');
}, 300);

let resizeId;
window.addEventListener('resize', function () {
    clearTimeout(resizeId);
    document.documentElement.classList.add('preload');
    resizeId = setTimeout(function () {
        document.documentElement.classList.remove('preload')
    }, 300);
});

// Fix NodeList.forEach in IE11
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}


/*
 * Accordion
 */

import Accordion from './functions/accordion';
const accordion = new Accordion();


/*
 * AppendAround
 */

import appendAround from './functions/appendAround';

window.addEventListener('load', function() {
    appendAround();
});


/*
 * Canvas Image Masking
 */

import Snap from 'snapsvg';

var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

if (!isIE11) {
    document.querySelectorAll('.js-canvas_mask').forEach(canvas => {
        let thumb = new Image();
        let shape = document.getElementById(canvas.dataset.shape);
        let width = shape.dataset.width;
        let height = shape.dataset.height;

        canvas.setAttribute('width', width);
        canvas.setAttribute('height', height);

        thumb.src = canvas.dataset.thumb;

        thumb.onload = function()
        {
            ctx = canvas.getContext('2d');
            data = Snap.path.toCubic(shape.getAttribute('d'));
            dataLength = data.length;

            createPath(ctx, data, dataLength, thumb);
        }

        function createPath(ctx, data, dataLength, thumb)
        {
            ctx.clearRect(0, 0, width, height);

            ctx.save();
            ctx.beginPath();

            for (let i = 0; i < dataLength; i++)
            {
                let seg = data[i];

                if (seg[0] === "M")
                {
                    ctx.moveTo(seg[1], seg[2]);
                }
                else
                {
                    ctx.bezierCurveTo(seg[1], seg[2], seg[3], seg[4], seg[5], seg[6]);
                }
            }

            ctx.clip();
            ctx.drawImage(thumb, 0, 0, width, height);
            ctx.closePath();
            ctx.restore();
        }
    });
} else {
    document.querySelectorAll('.js-canvas_mask, .js-canvas_border').forEach(elem => elem.style.display = 'none');
    document.querySelectorAll('.js-canvas_ie11').forEach(image => image.classList.add('show'));
}


/*
 * Cookies
 */

/*
import Cookies from 'js-cookie';

let cookieNotice = document.getElementById('cookie-notice');
if(cookieNotice != 'undefined' && cookieNotice != null && Cookies.get('cookie-notice') !== 'true') {
    document.querySelectorAll('.js-cookie_close').forEach(el => {
        el.addEventListener('click', event => {
            event.preventDefault();
            Cookies.set('cookie-notice', 'true', { expires : 7 });
            cookieNotice.classList.add('hide');
        }, false);
    });
}
*/


/*
 * Eventbrite
 */

let eventbriteLoaded = false;

if(typeof(document.querySelector('.js-eventbrite_trigger')) != 'undefined' && document.querySelector('.js-eventbrite_trigger') != null) {
    getScript('https://www.eventbrite.co.uk/static/widgets/eb_widgets.js',
        function () {
            console.log('Success');
            eventbriteLoaded = true;

            document.querySelectorAll('.js-eventbrite_trigger').forEach(btn => {
                if (eventbriteLoaded == true) {
                    createEventbriteWidget(btn.dataset.eventId, btn.id);
                }
            });
        },
        function () {
            console.log('Error');
        }
    );
}

function createEventbriteWidget(event_id, elem_id) {
    window.EBWidgets.createWidget({
        widgetType: 'checkout',
        eventId: event_id,
        modal: true,
        modalTriggerElementId: elem_id,
    });
}


/*
 * Filters
 */

if(typeof(document.querySelector('.js-filters')) != 'undefined' && document.querySelector('.js-filters') != null) {
    document.querySelector('.form-filters_btn').style.display = 'none';

    document.querySelectorAll('.js-filters_categories').forEach(select => {
        select.addEventListener('change', function(e) {
            if (e.target.value == '') {
                if (select.id == 'resource-filters_types') {
                    document.querySelectorAll('.archive-filters_btn-type input').forEach(input => {
                        input.checked = false;
                    });
                } else {
                    document.querySelectorAll('.archive-filters_btn-category input').forEach(input => {
                        input.checked = false;
                    });
                }
                document.querySelector('.js-filters').submit();
            } else {
                document.querySelector('.archive-filters_btn input[value="' + e.target.value + '"]').checked = true;
                document.querySelector('.js-filters').submit();
            }
        });
    });

    document.querySelectorAll('.archive-filters_btn input').forEach(input => {
        if (input.checked == false) {
            input.parentElement.parentElement.style.display = 'none';
        }
    });

    document.querySelectorAll('.js-filters_change').forEach(input => {
        input.addEventListener('change', function() {
            document.querySelector('.js-filters').submit();
        });
    });
}


/*
 * FlashMessage
 */

import FlashMessage from './functions/flash-message';
const flash_message = new FlashMessage();


/*
 * Forms
 */

// FormValidation
import './functions/form-validation';
document.querySelectorAll('.form.js-validate').forEach(form => {
    if (form.querySelector('.g-recaptcha')) {
        recaptcha = document.querySelector("meta[name='recaptcha_site_key']").getAttribute('content');
    } else {
        recaptcha = false;
    }

    new Validate(form, {
        recaptchaSiteKey: recaptcha,
        fieldFeedbackSelector: '.js-field-feedback span',
    });

    // Focus invalid field behaviour
    form.addEventListener('submit', function () {
        let invalidField = form.querySelector('.js-field.is-invalid');
        if (invalidField) {
            scrollToElem(invalidField);
        }
    });
});

// In-field Labels
document.querySelectorAll('.js-infield').forEach(field => {
    field.addEventListener('keyup', event => {
        field.setAttribute('value', field.value);
    });
});


/*
 * Focus
 */

document.querySelectorAll('.js-focus').forEach(elem => {
    let focus = elem.dataset.focus;

    elem.addEventListener('click', event => {
        setTimeout(function () {
            document.getElementById(focus).focus();
        }, 100);
    });
});


/*
 * GoTo Elem
 */

if(typeof(document.querySelector('.js-goto_elem')) != 'undefined' && document.querySelector('.js-goto_elem') != null) {
    document.querySelectorAll('.js-goto_elem').forEach(el => {
        if(typeof(document.getElementById(el.dataset.elem)) != 'undefined' && document.getElementById(el.dataset.elem) != null) {
            el.addEventListener('click', event => {
                scrollToElem(document.getElementById(el.dataset.elem));
            });
        }
    });
}

// On Page Load
if(window.location.hash) {
    scrollToElem(document.getElementById(window.location.hash.substring(1)));
}


/*
 * Main Navigation (Mobile)
 */

document.querySelectorAll('.js-nav_trigger').forEach(trigger => {
    trigger.addEventListener('click', () => {
        document.querySelectorAll('.js-nav_trigger').forEach(el => {
            el.classList.toggle('is-active');
        });
    });
});


/*
 * Modal Window
 */

import ModalWindow from './functions/modal-window';
const modal_window = new ModalWindow();


/*
 * Page Builder Fix
 */

if(typeof(document.querySelector('.pb-block_overlay-top')) != 'undefined' && document.querySelector('.pb-block_overlay-top') != null) {
    document.querySelectorAll('.pb-block_overlay-top').forEach(block => {

        if((block.previousSibling.classList.contains('pb-block_has-bg')) && (!block.previousSibling.classList.contains('fastlink-style_4'))) {
            block.previousSibling.classList.add('pb-block_padding-bottom');
        }
    });
}


/*
 * StickyHeader
 */

import StickyHeader from './functions/sticky-header';
const sticky_header = new StickyHeader();


/*
 * Submenu
 */

/*
if(typeof(document.querySelector('.js-submenu')) != 'undefined' && document.querySelector('.js-submenu') != null) {
    submenuTriggerHover = submenuHover = false;

    document.querySelectorAll('.js-submenu').forEach(submenu => {
        let submenuID = document.getElementById('megamenu');
        let submenuListID = document.getElementById(submenu.dataset.submenu);

        document.addEventListener('mousemove', event => {
            if (event.target == submenuID || submenuID.contains(event.target)) {
                submenuHover = true;
                submenuID.classList.add('is-active');
                submenuListID.classList.add('is-active');
                elemSiblings(submenuListID).forEach(sibling => sibling.classList.remove('is-active'));
            } else {
                if (!submenuTriggerHover)
                    submenuID.classList.remove('is-active');
            }
        }, false);

        submenu.addEventListener('mouseenter', () => {
            submenuTriggerHover = submenuHover = true;
            submenuID.classList.add('is-active');
            submenuListID.classList.add('is-active');
            elemSiblings(submenuListID).forEach(sibling => sibling.classList.remove('is-active'));
        });

        submenu.addEventListener('mouseleave', () => {
            submenuTriggerHover = false;
            setTimeout(function() {
                if (!submenuHover)
                    submenuID.classList.remove('is-active');
            }, 100);
        });
    });
}
*/


/*
 * Swiper
 */

import Swiper from './vendor/swiper';
document.querySelectorAll('.js-swiper').forEach(swiper => {
    let params = swiper.dataset.swiper;
    let mySwiper = new Swiper(swiper, JSON.parse(params));

    // Swiper Height
    document.addEventListener('lazyloaded', () => {
        if(swiper.dataset.autoheight)
            mySwiper.updateAutoHeight();
    });
});

// Swiper GOTO
document.querySelectorAll('.js-swiper_goto').forEach(goto => {
    goto.addEventListener('click', event => {
        event.currentTarget.blur();

        let swiperSlider = JSON.parse(goto.dataset.slider);
        let swiperSlide = goto.dataset.slide;

        for (var i = 0; i < swiperSlider.length; i++) {
            let mySwiper = document.getElementById(swiperSlider[i]).swiper;
            mySwiper.slideTo(swiperSlide);
        }
    });
});


/*
 * Tabbing
 */

const handleFirstTab = (e) => {
    if (e.keyCode === 9) {
        document.body.classList.add('has-tabbing-user');

        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
    }
};

const handleMouseDownOnce = () => {
    document.body.classList.remove('has-tabbing-user');

    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
};

window.addEventListener('keydown', handleFirstTab);


/*
 * Video
 */

import Video from './functions/video';
const video = new Video();


/* ------------------------------------------------------------------------------------------------------------------------ */
/* SUPPORT FUNCTIONS ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

/*
 * elemSiblings
 */

const elemSiblings = n => [...n.parentElement.children].filter(c=>c!=n);


/*
 * getScript
 */

function getScript(source, loadCallback, errorCallback) {
    var script = document.createElement('script');
    var prior = document.getElementsByTagName('script')[0];
    script.async = 1;

    script.onload = script.onreadystatechange = function (_, isAbort) {
        if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
            script.onload = script.onreadystatechange = null;
            script = undefined;

            if (!isAbort && loadCallback) {
                loadCallback();
            }
        }
    };

    if (errorCallback) {
        script.onerror = function () {
            errorCallback();
        };
    }

    script.src = source;
    prior.parentNode.insertBefore(script, prior);
}


/*
 * scrollToElem
 */

const scrollToElem = (elem, offset = 0) => {
    let headerHeight = document.querySelector('.header').offsetHeight;

    if(typeof(document.querySelector('.js-sticky')) != 'undefined' && document.querySelector('.js-sticky') != null) {
        headerHeight += document.querySelector('.js-sticky').offsetHeight;
    }

    const y = elem.getBoundingClientRect().top + window.pageYOffset - headerHeight - offset;

    setTimeout(function () {
        window.scrollTo({
            top : y,
            behavior : 'smooth'
        });
    }, 1);
};


/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANALYTICS -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */


/*
 * Cookie Controller
 */

import './functions/cookie-controller.min';

const cookieController = new CookieController({
    layout: 'banner',
    creditContent: '',
    tooltipTitle: '',
    tooltipContent: '',
    optionalGroups: [
        {
            name: 'analytics',
            label: 'Analytics',
            description: 'These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you have visited our site.',
            cookies: ['analytics'],
            recommendedState: 'on',
            defaultState: 'on',
            onAllow() {
                // (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                // new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                // j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                // 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                // })(window,document,'script','dataLayer','GTM-KX2C39T');
            },
            onDisallow() {
                // Cookies.set('analytics', false);
            },
        },
    ],
});


/*
 * GA Events
 */

import './functions/gaEvents';
