/* ######################################################################################################################## */
/* FUNCTION (GOOGLE ANALYTICS EVENTS TRACKING) ############################################################################ */
/* ######################################################################################################################## */
;(function(window, document, undefined) {
    if (typeof ga === 'undefined') {
        console.warn('GA undefined, check your analyics snippet has been added');
        return false;
    }

    /**
     * Fire the GA Event
     * @param {string} eventCategory
     * @param {string} eventAction
     * @param {string} eventLabel
     * @param {string} eventValue
     */

    var trackEvent = function(eventCategory, eventAction, eventLabel, eventValue) {
        ga('send', 'event', {
            eventCategory : eventCategory,
            eventAction   : eventAction,
            eventLabel    : eventLabel,
            eventValue    : eventValue
        });

        console.log(eventCategory, eventAction, eventLabel, eventValue);
    };

    /**
     * Select closest parent with polyfill
     * @param  {Object} el       element to search parents of
     * @param  {string} selector parent selector to search for
     * @return {Object}          closest element
     */

    function closest(el, selector) {
        var matchesFn;

        // find vendor prefix
        ['matches','webkitMatchesSelector','mozMatchesSelector','msMatchesSelector','oMatchesSelector'].some(function(fn) {
            if (typeof document.body[fn] == 'function') {
                matchesFn = fn;
                return true;
            }

            return false;
        });

        var parent;

        // traverse parents
        while(el) {
            parent = el.parentElement;

            if (parent && parent[matchesFn](selector))
                return parent;

            el = parent;
        }

        return null;
    }

    /**
     * Find an element's location or approximate sectioning tag
     * @param  {Object} el
     * @return {string}
     */

    var pagePosition = function(el) {
        if (typeof jQuery !== 'undefined' && el instanceof jQuery === true)
            el = el[0];

        var position = 'No page location data';                 // Set default no data string
        var ancestor = closest(el, '[data-event-position]');    // Look for closest ancestor with 'data-event-position' attr

        if (ancestor)
            position = ancestor.getAttribute('data-event-position');
        else {
            // If not found, go by approximation
            var approxAncestor = closest(el, 'header, main, nav, footer');

            if (approxAncestor)
                position = '<' +  approxAncestor.tagName + '>';
        }

        return position;
    };

    /**
     * Safely loop through NodeLists without using
     * [].forEach.call(NodeList) hack
     * @param {Array}    array
     * @param {Function} callback
     * @param {string}   scope
     */

    function forEach(array, callback, scope) {
        for (var i = 0; i < array.length; i++)
            callback.call(scope, i, array[i]);
    }

    /**
     * Social Platform links
     */

    var socialLinks = document.querySelectorAll('a[href*="facebook.com"], ' + 'a[href*="twitter.com"], a[href*="youtube.com"], a[href*="linkedin.com"], ' + 'a[href*="instagram.com"], a[href*="plus.google.com"]');

    forEach(socialLinks, function(i, el) {
        el.addEventListener('click', function() {
            trackEvent('Social Platform Link', 'Clicked', el.href + ' - ' + pagePosition(el));
        });
    });

    /**
     * Mailto links
     */

    var mailtoLinks = document.querySelectorAll('a[href^="mailto:"]');

    forEach(mailtoLinks, function(i, el) {
        el.addEventListener('click', function() {
            var email = el.href.replace('mailto:', '');
            trackEvent('Email Address', 'Clicked', email + ' - ' + pagePosition(el));
        });
    });

    /**
     * Tel links
     */

    var telLinks = document.querySelectorAll('a[href^="tel:"]');

    forEach(telLinks, function(i, el) {
        el.addEventListener('click', function() {
            var telephone = el.href.replace('tel:', '');
            trackEvent('Telephone Number', 'Clicked', telephone + ' - ' + pagePosition(el));
        });
    });

    /**
     * External Links
     */

    var extLinks = document.querySelectorAll('a[target="_blank"], a[rel="external"]');

    forEach(extLinks, function(i, el) {
        el.addEventListener('click', function() {
            trackEvent('External Link', 'Clicked', el.href);
        });
    });

    /**
     * Form submissions
     */

    var forms = document.querySelectorAll('form.is-submitted');

    forEach(forms, function(i, el) {
        trackEvent('Form', 'Submitted', el.getAttribute('data-form-name') + ' - ' + pagePosition(el));
    });

    /**
     * Expose globally
     */

    var gritEvent = {
        track : trackEvent,
        pagePosition : pagePosition
    };

    window.gritEvent = gritEvent;

}(window, document));
